//Dev URL
export const BACKEND_URL = 'https://crm-backend.bodhirealestate.in/api/';
export const BACKEND_URL_MEDIA =
  'https://crm-backend.bodhirealestate.in/uploads/';
export const FRONT_END_URL = 'https://crm-backend.bodhirealestate.in/uploads/';
export const BACKEND_URL_PDF =
  'https://crm-backend.bodhirealestate.in/property/';

// export const BACKEND_URL =
//   'https://anis-property-backend.asifsayyed.com/api/';
// export const BACKEND_URL_MEDIA =
//   'https://anis-property-backend.asifsayyed.com/uploads/';
// export const FRONT_END_URL =
//   'https://anis-property-backend.asifsayyed.com/uploads/';
// export const BACKEND_URL_PDF =
//   'https://crm-backend.asifsayyed.com/property/';

// export const BACKEND_URL = 'http://localhost:5000/api/';
// export const BACKEND_URL_MEDIA = 'http://localhost:5000/uploads/';
// export const FRONT_END_URL = 'http://localhost:5000/uploads/';
// export const BACKEND_URL_PDF = 'http://localhost:5000/property/';
//auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const OTP_FREEZE = 'OTP_FREEZE';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const TOKEN_CHECK = 'TOKEN_CHECK';
export const TOKEN_CHECK_FAIL = 'TOKEN_CHECK_FAIL';
export const LOGIN_ALERT_REMOVE = 'LOGIN_ALERT_REMOVE';
export const LOGOUT = 'LOGOUT';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILED = 'RESET_FAILED';
export const GET_USER_DATA_BY_EMAIL = 'GET_USER_DATA_BY_EMAIL';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';
export const SET_LOGOUT_PROVISION = 'SET_LOGOUT_PROVISION';
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const RESET_LOGIN_FAILED = 'RESET_LOGIN_FAILED';
export const OTP_SETTING = 'OTP_SETTING';
export const OTP_FALSE = 'OTP_FALSE';
export const SET_DIVISION_ROLE = 'SET_DIVISION_ROLE';
export const SET_COMPANY_ROLE = 'SET_COMPANY_ROLE';
export const IDLE_TIMEOUT = 'IDLE_TIMEOUT';
export const SET_OTP_TIMEOUT = 'SET_OTP_TIMEOUT';
export const SET_CHECK_TOKEN_BOOL = 'SET_CHECK_TOKEN_BOOL';
export const RESET_IDLE_TIME = 'RESET_IDLE_TIME';
export const LIST_OF_ACTIVE_PROCESS = 'LIST_OF_ACTIVE_PROCESS';
export const SINGLE_SELF_USER = 'SINGLE_SELF_USER';

export const SET_ALERT_PROPERTY = 'SET_ALERT_PROPERTY';
export const LIST_PROPERTY_DATA = 'LIST_PROPERTY_DATA';
export const LOADING_LIST_PROPERTY = 'LOADING_LIST_PROPERTY';
export const SET_SIGNLE_PROPERTY = 'SET_SIGNLE_PROPERTY';

//User
export const SET_ALERT_USER = 'SET_ALERT_USER';
export const LIST_USER_DATA = 'LIST_USER_DATA';
export const LOADING_LIST_USER = 'LOADING_LIST_USER';
export const SET_SIGNLE_USER = 'SET_SIGNLE_USER';

//LEad
export const SET_ALERT_LEAD = 'SET_ALERT_LEAD';
export const LIST_LEAD_DATA = 'LIST_LEAD_DATA';
export const LIST_SOURCE_DATA = 'LIST_SOURCE_DATA';
export const LIST_INTERACTION_DATA = 'LIST_INTERACTION_DATA';
export const LIST_ACTION_DATA = 'LIST_ACTION_DATA';
export const LOADING_LIST_LEAD = 'LOADING_LIST_LEAD';
export const LOADING_LIST_SOURCE = 'LOADING_LIST_SOURCE';
export const LOADING_LIST_INTERACTION = 'LOADING_LIST_INTERACTION';
export const LOADING_LIST_ACTION = 'LOADING_LIST_ACTION';
export const SET_SIGNLE_LEAD = 'SET_SIGNLE_LEAD';
export const SET_SIGNLE_INTERACTION = 'SET_SIGNLE_INTERACTION';
export const SET_SIGNLE_ACTION = 'SET_SIGNLE_ACTION';

//Task
export const SET_ALERT_TASK = 'SET_ALERT_TASK';
export const LIST_TASK_DATA = 'LIST_TASK_DATA';
export const LIST_TASK_DATA_USERS = 'LIST_TASK_DATA_USERS';
export const LOADING_LIST_TASK = 'LOADING_LIST_TASK';
export const LOADING_LIST_USER_TASK = 'LOADING_LIST_USER_TASK';
export const SET_SIGNLE_TASK = 'SET_SIGNLE_TASK';

//Task
export const SET_ALERT_MEETING = 'SET_ALERT_MEETING';
export const LIST_MEETING_DATA = 'LIST_MEETING_DATA';
export const LOADING_LIST_MEETING = 'LOADING_LIST_MEETING';
export const SET_SIGNLE_MEETING = 'SET_SIGNLE_MEETING';

//expense
export const SET_ALERT_EXPENSE = 'SET_ALERT_EXPENSE';
export const LIST_EXPENSE_DATA = 'LIST_EXPENSE_DATA';
export const LOADING_LIST_EXPENSE = 'LOADING_LIST_EXPENSE';
export const SET_SIGNLE_EXPENSE = 'SET_SIGNLE_EXPENSE';
export const LIST_EXPNSE_CATEGORY = 'LIST_EXPNSE_CATEGORY';

//projects
export const SET_ALERT_PROJECT = 'SET_ALERT_PROJECT';
export const LIST_PROJECT_DATA = 'LIST_PROJECT_DATA';
export const LOADING_LIST_PROJECT = 'LOADING_LIST_PROJECT';
export const SET_SIGNLE_PROJECT = 'SET_SIGNLE_PROJECT';

//customer
export const SET_ALERT_CUSTOMER = 'SET_ALERT_CUSTOMER';
export const LIST_CUSTOMER_DATA = 'LIST_CUSTOMER_DATA';
export const LOADING_LIST_CUSTOMER = 'LOADING_LIST_CUSTOMER';
export const SET_SIGNLE_CUSTOMER = 'SET_SIGNLE_CUSTOMER';

//transacton
export const SET_ALERT_TRANSACTION = 'SET_ALERT_TRANSACTION';
export const LIST_TRANSACTION_DATA = 'LIST_TRANSACTION_DATA';
export const LOADING_LIST_TRANSACTION = 'LOADING_LIST_TRANSACTION';
export const SET_SIGNLE_TRANSACTION = 'SET_SIGNLE_TRANSACTION';

//transacton
export const SET_ALERT_SALARY = 'SET_ALERT_SALARY';
export const LIST_SALARY_DATA = 'LIST_SALARY_DATA';
export const LOADING_LIST_SALARY = 'LOADING_LIST_SALARY';
